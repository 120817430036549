
import { defineComponent } from 'vue';
import TheScheduleUpdate from '@/components/TheScheduleUpdate.vue';
import TheScheduleTable from '@/components/TheScheduleTable.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { ACTION } from '@/store/actions';
import { formatDate, timezoneDate, todayEnd } from '@/utils';
import router from '@/router/router';
import { STATE } from '@/constants';

export default defineComponent({
    name: 'Schedule',
    components: { TheScheduleUpdate, TheScheduleTable },
    data() {
        return {
            filterSetName: '__schedule__'
        }
    },
    computed: {
        canUpdateSchedule(): boolean {
            return store.getters.isAdmin;
        },
        urlDateAnchor(): Date {
            return this.$route.hash === ""?
                todayEnd(): 
                new Date(this.$route.hash.slice(1)); // strips the # from the hash
        },
        emptySchedule(): boolean {
            return store.getters.emptySchedule;
        }
    },
    watch: {
        $route(to) {
            if (to.path === '/schedule') {
                store.commit(MUTATION.RESET_SCHEDULE_FILTERS, this.filterSetName);
            }
        }
    },
    mounted() {
        store.commit(MUTATION.RESET_SCHEDULE_FILTERS, this.filterSetName);
        if(store.state.teamsRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_TEAMS);
        }
        if(store.state.fieldsRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_FIELDS);
        }
    },
    created() {
        if (this.$route.hash === "") {
            const dateStr = formatDate(timezoneDate(todayEnd()).toISOString());
            router.replace(
                `${this.$route.fullPath}#${dateStr}`
            );
        }
    }
});
